import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const PostListByTagPage: React.FC<PageProps<Queries.BookQuery>> = ({
  data,
}) => {
  const title = data.contentfulBook?.title ?? "";
  const link = data.contentfulBook?.link ?? "/#";

  const description =
    "こんな人におすすめ：" + data.contentfulBook?.recommend?.join("/");

  const seoProps: GatsbySeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      url: `https://boctoz.com/book/${data.contentfulBook?.id}`,
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="flex flex-col items-center gap-8 md:flex-row">
        {data.contentfulBook?.image?.gatsbyImageData && (
          <GatsbyImage
            image={data.contentfulBook.image.gatsbyImageData}
            alt={title}
            className="rounded object-contain"
          />
        )}
        <div className="prose max-w-full pt-12 pb-8">
          <a href={link} target="_blank" className="" rel="noreferrer">
            <h1 className="underline hover:text-gray-700 ">{title}</h1>
          </a>
          <h2>こんな人におすすめ</h2>
          <ul>
            {data.contentfulBook?.recommend?.map((el) => (
              <li key={el}>{el}</li>
            ))}
          </ul>
        </div>
      </div>

      <h2 className="mb-8 border-b-2 pt-12 pb-4 text-2xl font-bold">
        関連ポスト
      </h2>
      <ul className="flex list-none flex-wrap gap-4">
        {data.allContentfulPost.nodes.map((node) => (
          <>
            {node.ogImage?.gatsbyImageData && (
              <Link
                to={`/post/${node.slug}`}
                key={node.slug}
                className="group md:w-[calc(50%-0.5rem)]"
              >
                <li className="rounded shadow transition-shadow group-hover:shadow-lg">
                  <GatsbyImage
                    image={node.ogImage?.gatsbyImageData}
                    alt={""}
                    className="w-full rounded-t object-contain"
                  />
                  <h2 className="m-0 px-2 pt-3 font-bold">{node.title}</h2>
                  <p className="px-2 pt-1 pb-2 text-gray-400">
                    Published at {node.createdAt}
                  </p>
                </li>
              </Link>
            )}
          </>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  query Book($id: String) {
    contentfulBook(id: { eq: $id }) {
      id
      image {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED, width: 200)
      }
      recommend
      title
      link
    }
    allContentfulPost(filter: { book: { elemMatch: { id: { eq: $id } } } }) {
      nodes {
        slug
        title
        createdAt(formatString: "YYYY-MM-DD")
        ogImage {
          gatsbyImageData
        }
      }
    }
  }
`;

export default PostListByTagPage;
